<template>
  <div class="financial-estimate-rule-form">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button v-if="errorKeys.length" type="primary" @click="handleErrorClick">下一错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="spreadContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import SpreadMixin from '@/mixins/SpreadMixin'
import FinancialEstimateRuleSpreadInfo from '@/views/FinancialEstimateRuleManage/FinancialEstimateRuleSpreadInfo'
import financialEstimateService from '@/services/financialEstimateService'
import financialEstimateRuleService from '@/services/financialEstimateRuleService'
import FinancialEstimateRuleModel from '@/model/FinancialEstimateRuleModel'
import auth from '@/common/auth'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import utility from '@/common/utility'

export default {
  name: 'FinancialEstimateRuleForm',
  components: {
    MySearchBar
  },
  mixins: [
    SpreadMixin
  ],
  props: {
  },
  watch: {
  },
  data () {
    return {
      spreadInfo: FinancialEstimateRuleSpreadInfo,
      financialEstimateList: [],
      financialEstimateKeyList: [],
      meterageList: [],
      meterageListMap: {},
      financialEstimateRuleList: [],
      currentErrorIndex: 0,
      digits: 0
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errorMap)
    }
  },
  methods: {
    getFinancialEstimateList () {
      financialEstimateService.list({}, { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          this.financialEstimateList = res.data.data

          this.financialEstimateKeyList = []
          for (let i = 0; i < this.financialEstimateList.length; ++i) {
            const financialEstimateModel = this.financialEstimateList[i]
            if (financialEstimateModel.children && financialEstimateModel.children.length) {
              // 存在子节点，则遍历子节点
              for (let j = 0; j < financialEstimateModel.children.length; ++j) {
                this.financialEstimateKeyList.push(financialEstimateModel.children[j].financialEstimateCode)
              }
            } else {
              // 已经是子节点，push到结果数组
              this.financialEstimateKeyList.push(financialEstimateModel.financialEstimateCode)
            }
          }
          console.log('financialEstimateKeyList', this.financialEstimateKeyList)
          this.generateSpreadInfo()
          this.initWorkbook(this.$refs.spreadContainer)
          this.getMeterageList()
          this.bindCellChanged()
          this.bindClipboardPasted()
          this.bindDragFillBlockCompleted()
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    generateSpreadInfo () {
      let start = 8
      for (let i = 0; i < this.financialEstimateList.length; ++i) {
        const item = this.financialEstimateList[i]
        const newItem = {
          index: start,
          name: item.financialEstimateName,
          width: '250'
        }
        newItem.children = []
        if (item.children && item.children.length) {
          for (let j = 0; j < item.children.length; ++j) {
            this.meterageListMap[start + 2 * j] = item.children[j]
            this.meterageListMap[start + 2 * j + 1] = item.children[j]
            newItem.children.push({
              index: start + 2 * j,
              name: item.children[j].financialEstimateName,
              width: '90'
            })
          }
        } else {
          this.meterageListMap[start] = item
          this.meterageListMap[start + 1] = item
        }
        this.spreadInfo.headerColumns.push(newItem)
        start += (newItem.children.length ? newItem.children.length : 1) * 2
      }
      this.spreadInfo.headerColumnCount = start
    },
    // overrid
    initWorksheetColumns () {
      this.spread.suspendPaint()

      const start = 8

      if (this.spreadInfo.headerColumns && this.spreadInfo.headerColumns.length) {
        // 设置头部有多少行
        this.worksheet.setRowCount(this.spreadInfo.headerRowCount + 1, GC.Spread.Sheets.SheetArea.colHeader)
        // 设置共有多少列
        this.worksheet.setColumnCount(this.spreadInfo.headerColumnCount, GC.Spread.Sheets.SheetArea.colHeader)
        // 初始化每列数据
        this.spreadInfo.headerColumns.forEach(column => {
          if (column.children && column.children.length) {
            // 有子列，所以需要合并列，并且给子列赋值
            this.worksheet.addSpan(0, column.index, 1, column.index < start ? column.children.length : 2 * column.children.length, GC.Spread.Sheets.SheetArea.colHeader)
            column.children.forEach(childColumn => {
              this.worksheet.addSpan(1, childColumn.index, 1, 2, GC.Spread.Sheets.SheetArea.colHeader)
              this.worksheet.setValue(1, childColumn.index, childColumn.name, GC.Spread.Sheets.SheetArea.colHeader)
              this.worksheet.setColumnWidth(childColumn.index, childColumn.width)
            })
          } else {
            // 没有子列，所以需要合并行
            if (column.index < start) {
              this.worksheet.addSpan(0, column.index, this.spreadInfo.headerRowCount, 1, GC.Spread.Sheets.SheetArea.colHeader)
            } else {
              this.worksheet.addSpan(0, column.index, this.spreadInfo.headerRowCount, 2, GC.Spread.Sheets.SheetArea.colHeader)
            }
            this.worksheet.setColumnWidth(column.index, column.width)
          }
          this.worksheet.setValue(0, column.index, column.name, GC.Spread.Sheets.SheetArea.colHeader)
        })
      }

      for (let i = 0; i < start; ++i) {
        this.worksheet.addSpan(0, i, 3, 1, GC.Spread.Sheets.SheetArea.colHeader)
      }

      let flag = true
      for (let i = start; i < this.spreadInfo.headerColumnCount; ++i) {
        this.worksheet.setValue(2, i, flag ? '百分比' : '金额', GC.Spread.Sheets.SheetArea.colHeader)
        this.worksheet.setColumnWidth(i, 110)
        flag = !flag
      }

      this.spread.resumePaint()
    },
    getMeterageList () {
      financialEstimateRuleService.getMeterageList(auth.getUserInfo().projectGuid, auth.getUserInfo().tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.meterageList = res.data.data
            this.bindData(this.meterageList)
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    bindData (data) {
      this.worksheet.setRowCount(data.length)
      let dataArray = []
      data.forEach(item => {
        let rowData = []

        rowData.push(item.MeterageDisplayCode)
        rowData.push(item.MeterageName)
        rowData.push(item.Unit)
        rowData.push(parseFloat(item.Num).toFixed(3))
        rowData.push(parseFloat(item.Price).toFixed(2))
        rowData.push(parseFloat(item.Amount).toFixed(0))
        rowData.push(parseFloat(item.DeclareAmount).toFixed(0))
        rowData.push(0)
        let totalAmount = 0
        for (let i = 0; i < this.financialEstimateKeyList.length; ++i) {
          const financialEstimateKey = this.financialEstimateKeyList[i]
          rowData.push(item[`${financialEstimateKey}B`] ? item[`${financialEstimateKey}B`] : 0)
          let amount = item[`${financialEstimateKey}J`]
          amount = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount)
          rowData.push(amount.toFixed(this.digits))
          totalAmount += amount
        }
        rowData[7] = totalAmount
        dataArray.push(rowData)
      })
      this.worksheet.setArray(0, 0, dataArray)

      this.setProtection()
    },
    setProtection () {
      this.worksheet.options.isProtected = true
      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.locked = false

      for (let i = 8; i < this.spreadInfo.headerColumnCount; ++i) {
        this.worksheet.setStyle(-1, i, unLockStyle)
      }
    },
    bindCellChanged () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ValueChanged, (sender, info) => {
        // 如果是百分比列，那么出对应金额
        console.log(info.row, info.col, info.newValue)
        if (info.col % 2 === 0 && info.newValue && !isNaN(info.newValue)) {
          let meterageList = this.meterageList[info.row]
          let amount = utility.floatMultiple(meterageList.DeclareAmount, info.newValue)
          amount = utility.floatDivide(amount, 100)
          this.worksheet.setValue(info.row, info.col + 1, amount.toFixed(this.digits))
        }
        this.rowCompute(info.row)
      })
    },
    bindDragFillBlockCompleted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this.rowCompute(info.fillRange.row + i)
        }
      })
    },
    bindClipboardPasted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        for (let i = 0; i < info.cellRange.rowCount; ++i) {
          this.rowCompute(i + info.cellRange.row)
        }
      })
    },
    markError (rowIndex, flag) {
      if (flag) {
        this.setError(null, rowIndex, 7)
      } else {
        this.setError('请检查您的输入！', rowIndex, 7)
      }
    },
    rowCompute (rowIndex) {
      let meterageList = this.meterageList[rowIndex]

      // 首先判断数据是否都合法
      const start = 8
      let flag = false
      for (let i = start; i < this.spreadInfo.headerColumnCount; ++i) {
        const cellValue = this.worksheet.getValue(rowIndex, i)
        if (isNaN(cellValue)) {
          this.markError(rowIndex, 0)
          return
        }
        if (cellValue !== null && cellValue !== '' && parseFloat(cellValue) !== 0) {
          flag = true
        }
      }
      this.markError(rowIndex, 1)

      // 计算并判断合计值是否等于剩余数量
      if (flag) {
        let sumAmount = 0
        for (let i = start; i < this.spreadInfo.headerColumnCount; i += 2) {
          let amount = parseFloat(this.worksheet.getValue(rowIndex, i + 1)).toFixed(this.digits)
          this.worksheet.setValue(rowIndex, i + 1, amount)
          sumAmount = utility.floatAdd(sumAmount, amount)
        }
        this.worksheet.setValue(rowIndex, 7, sumAmount)
        if (sumAmount.toFixed(this.digits) !== parseFloat(meterageList.DeclareAmount).toFixed(this.digits)) {
          this.markError(rowIndex, 0)
          return
        }
        this.markError(rowIndex, 1)
      }
      this.currentErrorIndex = 0
    },
    translate () {
      let dataTable = this.spread.toJSON({
        ignoreStyle: true
      }).sheets[this.spreadInfo.sheetName].data.dataTable

      this.financialEstimateRuleList = []
      for (let key in Object.keys(dataTable)) {
        let row = dataTable[key]
        let start = 8
        let sumPercent = 0
        let sumAmount = 0
        let flag = false
        for (let i = start; i < this.spreadInfo.headerColumnCount; i += 2) {
          let percent = parseFloat(row[i].value)
          let amount = parseFloat(row[i + 1].value)
          if (percent === 0 || amount === 0) {
            // 数据无效
            continue
          } else {
            flag = true
            // 处理有效数据
            sumPercent = utility.floatAdd(sumPercent, percent)
            sumAmount = utility.floatAdd(sumAmount, amount)
            let financialEstimateRuleModel = new FinancialEstimateRuleModel({
              meterageListGuid: this.meterageList[parseInt(key)].MeterageListGuid,
              surplusAmount: parseFloat(this.meterageList[parseInt(key)].DeclareAmount),
              financialEstimateGuid: this.meterageListMap[parseInt(i)].financialEstimateGuid,
              amount: amount,
              financialEstimateRulePercent: percent,
              tenderGuid: auth.getUserInfo().tenderGuid,
              isEffective: 1
            })
            this.financialEstimateRuleList.push(financialEstimateRuleModel)
          }
        }
        if (flag && sumAmount.toFixed(this.digits) !== parseFloat(this.meterageList[parseInt(key)].DeclareAmount).toFixed(this.digits)) {
          this.markError(parseInt(key), 0)
        } else {
          this.markError(parseInt(key), 1)
        }
      }
      this.currentErrorIndex = 0
    },
    handleSaveClick () {
      this.$myLoading()
      setTimeout(() => {
        this.translate()
        if (Object.keys(this.errorMap).length === 0) {
          financialEstimateRuleService.batchAdd(this.financialEstimateRuleList)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
              this.$loading().close()
            })
            .catch(err => {
              console.log(err)
              this.$loading().close()
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            })
        } else {
          this.$loading().close()
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      }, 200)
    },
    handleErrorClick () {
      let rowIndex = this.errorMap[this.errorKeys[this.currentErrorIndex]].rowIndex
      this.worksheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
      this.currentErrorIndex = (this.currentErrorIndex + 1) % this.errorKeys.length
    }
  },
  mounted () {
    this.getFinancialEstimateList()
  }
}
</script>

<style scoped lang="scss">
.financial-estimate-rule-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-container {
    flex: 1;
  }
}
</style>
