const FinancialEstimateRuleSpreadInfo = {
  sheetName: '概算规则',
  headerRowCount: 2,
  headerColumnCount: 8,
  headerColumns: [
    {
      index: 0,
      name: '子目号',
      width: '110'
    },
    {
      index: 1,
      name: '子目名称',
      width: '280'
    },
    {
      index: 2,
      name: '单位',
      width: '60'
    },
    {
      index: 3,
      name: '合同数量',
      width: '100'
    },
    {
      index: 4,
      name: '合同单价',
      width: '100'
    },
    {
      index: 5,
      name: '合同金额',
      width: '100'
    },
    {
      index: 6,
      name: '剩余金额',
      width: '100'
    },
    {
      index: 7,
      name: '合计分配金额',
      width: '100'
    }
  ]
}

export default FinancialEstimateRuleSpreadInfo
